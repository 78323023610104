/* src/Chat.css */

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.input {
  padding: 10px;
  border-top: 1px solid #ccc;
}

.send-button {
  background: linear-gradient(to right, #0084ff, #0099ff);
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.send-button:hover {
  background-color: #006bbf;
}

.rce-mbox-right {
  margin-right: 0px;
}

.rce-mbox.rce-mbox-right {
  background: linear-gradient(to right, #0084ff, #0099ff);
  color: white;
  border-radius: 15px;
}

.rce-mbox-left .rce-mbox {
  background: linear-gradient(to right, #e0e0e0, #f0f0f0);
  color: black;
}

.rce-mbox {
  padding: 15px;
  border-radius: 15px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.rce-mbox-left .rce-mbox {
  margin-right: 80px;
}

.rce-mbox-left .rce-mbox {
  margin-left: 0px;
}

.rce-mbox-right .rce-mbox {
  margin-right: 0px;
}

.rce-mbox-right .rce-mbox-text, .rce-mbox-left .rce-mbox-text {
  color: inherit;
}

.rce-mbox-right .rce-mbox-time {
  color: rgba(255, 255, 255, 0.7);
}

.rce-mbox-left .rce-mbox-time {
  color: rgba(0, 0, 0, 0.5);
}

.rce-mbox-right-notch, .rce-mbox-left-notch {
  display: none;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #888;
  font-size: 1.2rem;
}

.typing-indicator {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 10px;
  font-style: italic;
  color: #666;
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  height: 75px;
}

/* .study-dropdown {
  max-width: 200px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.navigation-bar div div {
  width: 200px;
}

.navigation-bar div {
  width: '33%';
} */

.rce-mbox-forward-right {
  visibility: visible;
  opacity: 1;
}

.rce-mbox-forward-left {
  display: none;
}

